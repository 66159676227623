import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { CommonService } from '../../../../services/common.services';
import { EndpointsURL } from '../../../../services/endpoints';

@Component({
  selector: 'app-assign-delivery',
  templateUrl: './assign-delivery.component.html',
  styleUrls: ['./assign-delivery.component.scss']
})
export class AssignDeliveryComponent implements OnInit {
  typeDeliveriesData;
  data;
  delivery = 0;
  loading = true;
  processing: boolean;
  constructor(
    private commonService: CommonService,
    private activeModal: NgbActiveModal,
  ) { }

  ngOnInit(): void {
    this.loading = true;
    this.commonService.http.httpGET(EndpointsURL.DELIVERIES_TO_ASSIGN).subscribe(response => {
      this.typeDeliveriesData = response.data.map(x => {
        return  {id: x.id, text: x.name};
      });
      this.loading = false;
    }, error => this.loading = false)
  }

  close() {
    this.activeModal.dismiss();
  }

  save() {
    this.processing = true;
    const payload = {deliveryId:  this.delivery, orderId: this.data.id};
    this.commonService.http.httpPOST(EndpointsURL.ASSING_DELIVERY_TO_ORDER, payload).subscribe(response => {
      this.commonService.showToast(response.message, response.successfull ? 'success' : 'danger')
      if (response.successfull) {
        this.activeModal.close(response.data);
      }
      this.processing = false
    }, error => this.processing = false)
  }

}
