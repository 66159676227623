export class EndpointsURL {

  // Endpoints
  public static LOGIN: string = 'login';
  public static DASHBOARD: string = 'getDashboard';
  public static CATEGORIES: string = 'categories';
  public static CATEGORIES_WITH_PRODUCTS: string = 'categories?withProducts=true';
  public static ADDONS: string = 'addons';
  public static PRODUCTS: string = 'products';
  public static PRODUCTS_INVENTORY: string = 'inventory';
  public static INVENTORY_MOVEMENT: string = 'inventory-movement';
  public static PRODUCTS_WITH_ADDONS: string = 'products?withAddons=true';
  public static ZONES: string = 'zones';
  public static DISTANCE_COSTS: string = 'distance-cost';
  public static INVENTORY_PRODUCTS_REMOVE: string = 'inventory/';
  public static PRODUCTS_REMOVE: string = 'products/';
  public static USERINFO: string = 'userinfo';
  public static STORECATEGORY: string = 'store-categories';
  public static CITIES: string = 'cities';
  public static TYPES: string = 'types';
  public static PAYMENTS: string = 'payments';
  public static CURRENCIES: string = 'currencies';
  public static DELIVERIES: string = 'deliveries';
  public static ORDERS: string = 'orders';
  public static ENTRUST: string = 'entrust';
  public static VALIDATE_ADDRESS: string = 'validate-available-address';
  public static PAYMENT_TYPES: string = 'payment-types';
  public static KITCHEN_USERS: string = 'kitchen-users';


  // Reportes
  public static TOTAL_SALES_CARDS: string = 'total-sales-cards';
  public static TOTAL_SALES_FILTER: string = 'total-sales-filter';

  // Ofertas
  public static OFFER: string = 'discounts';



  public static THEME_COLORS: string = 'theme-colors';

  public static CHANGE_STORE_OPEN: string = 'change-store-open';
  public static SAVE_BASIC_INFO: string = 'save/basic-info';
  public static SAVE_CHARGES: string = 'save/charges';
  public static SAVE_SCHEDULE: string = 'save/schedule';
  public static SAVE_INTEGRATIONS: string = 'save/integrations';
  public static SAVE_EXCHANGES: string = 'save/exchanges';
  public static CHANGE_STATUS: string = 'order-change-status';
  public static CHANGE_STATUS_ENTRUST: string = 'entrust-change-status';

  public static DELIVERIES_TO_ASSIGN: string = 'deliveries-to-assign';
  public static UNASSIGN_DELIVERY: string = 'unassign-delivery';
  public static BRACHES_TO_ASSIGN: string = 'branches-to-assign';
  public static ASSING_DELIVERY_TO_ORDER: string = 'assing-delivery-to-order';
  public static ASSING_BRANCH_TO_ORDER: string = 'assing-branch-to-order';
  public static PAYMENTS_REMOVE: string = 'payments/';
  public static CURRENCIES_REMOVE: string = 'currencies/';
  public static DEFAULT_CURRENCY: string = 'set-default-currency';

}
