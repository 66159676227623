import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { throwError, from } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { environment } from '../../environments/environment';
import { getObject } from '../core/storage/storage-cap';
import { CommonService } from './common.services';
import { cibEventbrite } from '@coreui/icons';


// import { CommonService } from './common.services';
// import { CommonService } from '@services/common.services';

@Injectable({
  providedIn: 'root'
})
export class InterceptorService implements HttpInterceptor {
  myToken: any;
  loaderToShow: any;

  constructor(
    private router: Router,
    private commonService: CommonService
  ) { }

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    // convert promise to observable using 'from' operator y poder capturar el token del local storare
    return from(this.handle(req, next))
  }

  async handle(request: HttpRequest<any>, next: HttpHandler) {

    let token = getObject(environment.token);
    if (token && !request.url.includes('firebasedynamiclinks')) {
      request = request.clone({
        setHeaders: {
          Authorization: 'Bearer ' + token.token,
          // 'Cache-Control': 'no-cache',
          // 'Pragma': 'no-cache',
          // 'Expires': '0',
          'accept': 'application/json'
        }
      });
    }

    if (!request.headers.has('Accept')) {
      request = request.clone({
        headers: request.headers.set('Accept', 'application/json')
      });
    }

    return next.handle(request).pipe(
      // retry(1),
      map((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          if (event.status === 200 && !event.body.successfull  && !request.url.includes('firebasedynamiclinks')) {
            this.commonService.showToast(`${event.body.message}`, 'danger');
          }
        }

        return event;
      }),
      catchError((error: HttpErrorResponse) => {
        if (error.status === 401) {
          localStorage.clear();
          this.router.navigate(['login']);
        }

        if (error.status === 422) {
          this.commonService.showToast(error.error.message, 'danger');
        }

        const err = new Error('test'); 
        return throwError(() => err);
      })).toPromise();
  } 
}
