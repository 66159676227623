import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { CommonService } from '../../../../services/common.services';

@Component({
  selector: 'app-print-order',
  templateUrl: './print-order.component.html',
  styleUrls: ['./print-order.component.scss']
})
export class PrintOrderComponent implements OnInit {
  data;
  constructor(
    private activeModal: NgbActiveModal,
    public commonService: CommonService
  ) { }

  ngOnInit(): void {
    console.info(this.data)
  }

  close() {
    this.activeModal.close();
  }

  save() {

  }

  get namePayment() {
    switch (this.data.payment_method) {
      case 1:
        return 'Efectivo';
        break;

      default:
        return 'Comprobante de pago';
        break;
    }
  }

}

