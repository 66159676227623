import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { SearchCountryField, CountryISO, PhoneNumberFormat } from 'ngx-intl-tel-input';
import { CommonService } from '../../../../services/common.services';
import { EndpointsURL } from '../../../../services/endpoints';

@Component({
  selector: 'app-add-payment',
  templateUrl: './add-payment.component.html',
  styleUrls: ['./add-payment.component.scss']
})
export class AddPaymentComponent implements OnInit {
  data;
  payments: Array<any> = [];
  paymentsData: Array<any> = [];
  paymentMethod;
  paymentAmount;
  paymentAttach;
  base64Output;
  processingImage: boolean;
  orderType = 1;
  deliveryType = 1;
  scheduledTime;
  address;
  name;
  phone;
  notes;
  separateDialCode = false;
	SearchCountryField = SearchCountryField;
	CountryISO = CountryISO;
  PhoneNumberFormat = PhoneNumberFormat;
	preferredCountries: CountryISO[] = [CountryISO.Venezuela];
  processing: boolean;
  checkingAddress = false;
  zoneNoAvailable = false;
  prices;
  coords: any;
  constructor(
    private activeModal: NgbActiveModal,
    private commonService: CommonService
  ) { }

  ngOnInit(): void {
    this.commonService.http.httpGET(EndpointsURL.PAYMENT_TYPES).subscribe(response => {
      this.payments = response.data;
      this.paymentsData = response.data.map(payment => {
        return {id: payment.id, text: payment.name};
      });
    });
  }

  close() {
    this.activeModal.close();
  }

  save() {
    // check information
    this.processing = true;
    if (this.totalOrderPending > 0) {
      this.commonService.showToast('Debes agregar las formas de pago y montos que completen el total a pagar del pedido','danger',3000, 'Faltan datos');
      this.processing = false;
      return;
    }

    if (this.orderType === 1 && (!this.address || !this.phone ||  !this.name || !this.prices)) {
      this.commonService.showToast('Debes completar los datos de entrega y/o validar la dirección','danger',3000, 'Faltan datos');
      this.processing = false;
      return;
    }

    if (this.deliveryType === 2 && !this.scheduledTime) {
      this.commonService.showToast('Debes indicar la fecha y hora en que quieres sea entregado el pedido','danger',3000, 'Faltan datos');
      this.processing = false;
      return;
    }

    const dCharges = this.prices ? this.prices.total : 0;
    debugger;
    const payload = {
      type: this.orderType,
      total: (this.totalOrder + dCharges),
      notes: this.notes,
      scheduledTime: this.scheduledTime,
      name: this.name || '',
      phone: this.phone ? this.phone.e164Number : '',
      address: this.address || '',
      lat: (this.coords) ? this.coords.lat : 0,
      lng: (this.coords) ? this.coords.lng : 0,
      payment_methods: this.data.payment_methods,
      products: this.data.products,
      dCharges
    }
    this.commonService.http.httpPOST(EndpointsURL.ORDERS, payload).subscribe(response => {
      this.processing = false;
      if (response.successfull) {
        this.commonService.showToast('El pedido #'+response.data.id+' se creado correctamente');
        this.commonService.sentCountOrders(response.data.orders);
        this.activeModal.close(true);
      }
    }, error => {
      this.processing = false;
    });


  }

  get totalOrder() {
    const reducer = (previousValue, currentValue) => previousValue + (((currentValue.priceWithAddons) ? currentValue.priceWithAddons : parseFloat(currentValue.price.price)) * currentValue.qty);
    return this.data.products.reduce(reducer, 0);
  }

  get totalOrderPending() {
    const reducer = (previousValue, currentValue) => previousValue + currentValue.amount;
    const dCharges = (this.prices) ? this.prices.total : 0;
    return (this.totalOrder + dCharges) - this.data.payment_methods.reduce(reducer, 0);
  }

  get paymentAmountRequired() {
    if (!this.paymentMethod) return false;

    const payment = this.payments.find(x => x.id.toString() === this.paymentMethod);
    if (payment.allowAttach && payment.isAttachRequired) {
      return true;
    }
  }

  get paymentAmountAllow() {
    if (!this.paymentMethod) return false;

    const payment = this.payments.find(x => x.id.toString() === this.paymentMethod);
    if (payment.allowAttach) {
      return true;
    }
  }

  onFileSelected(event) {
    this.processingImage = true;
    this.commonService.convertFile(event.target.files[0]).subscribe(base64 => {
      this.base64Output = 'data:' + event.target.files[0].type + ';base64,'+ base64;
      // this.form.controls.img.setValue(this.base64Output);
      this.processingImage = false;
    });
  }

  addPaymentMethod() {
    const payment = this.payments.find(x => x.id.toString() === this.paymentMethod);
    this.data.payment_methods.push({
      store_payment_id: payment.id,
      store_payment_name: payment.name,
      amount: this.paymentAmount,
      attach: this.base64Output
    });
    this.paymentAmount = undefined;
    this.paymentMethod = '';
  }

  removePaymentMethod(index) {
    this.data.payment_methods.splice(index, 1);
  }

  calculatePriceDelivery() {
    this.checkingAddress = true;
    this.zoneNoAvailable = false;
    this.prices = undefined;
    this.coords = undefined;
    const payload = {
      lat: 0,
      lng: 0,
      address: '',
      calculatePrice: true,
    }

    if (this.address.length < 3) {
        this.commonService.showToast('Debe indicar la dirección de entrega', 'danger', 3000);
        this.checkingAddress = false;
        return;
    }

    payload.address = this.address;

    this.commonService.http.httpPOST(EndpointsURL.VALIDATE_ADDRESS, payload).subscribe(response => {
      if (this.orderType != 1) {
        this.checkingAddress = false;
        return;  
      }

      if (response.data.isAvailableDelivery) {
        this.prices = response.data.prices;
        this.coords = response.data.coords;
      } else {
        this.zoneNoAvailable = true;
      }
      this.checkingAddress = false;
    }, error => this.checkingAddress = false);
  }

  checkOrderType(event) {
    if (this.orderType === 2) {
      this.prices = undefined;
    }
  }
}

