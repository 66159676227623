import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { CommonService } from '../../../services/common.services';
import { EndpointsURL } from '../../../services/endpoints';

@Component({
  selector: 'app-orders',
  templateUrl: './orders.component.html',
  styleUrls: ['./orders.component.scss']
})
export class OrdersComponent implements OnInit {
  status = '0';
  data: Array<any> = [];
  @BlockUI('ordersload') blockUI: NgBlockUI;
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private commonService: CommonService
  ) { }

  ngOnInit(): void {
    this.route.paramMap.subscribe((params: ParamMap) => {
      this.status = params.get('id').toString();
      this.loadData();
    });

    this.commonService.timeAgoTwoDates;
  }

  loadData() {
    this.blockUI.start();
    this.commonService.setBlockIU(true);
    this.commonService.http.httpGET(EndpointsURL.ORDERS.concat(`?status=${this.status}`)).subscribe(response => {
      this.data = response.data.orders;
      this.commonService.sentCountOrders(response.data.orderCount);
      this.commonService.setBlockIU(false);
      this.blockUI.stop();
    }, error => {this.commonService.setBlockIU(false);this.blockUI.stop();});
  }

  assignBranch(order, index) {
    
  }
  get title() {
    switch (this.status.toString()) {
      case '0':
        return 'nuevos';
        break;
      case '1':
        return 'en progreso';
        break;
      case '1-3-4':
          return 'en progreso';
          break;
      case '2':
        return 'cancelados';
        break;
      case '5':
        return 'completados';
        break;
      default:
        return this.router.navigateByUrl('404');
        break;
    }
  }

  create() {
    this.router.navigateByUrl('/manager/orders/new/create');
  }

}
