import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { CommonService } from '../../../../services/common.services';
import { EndpointsURL } from '../../../../services/endpoints';

@Component({
  selector: 'app-assign-branch',
  templateUrl: './assign-branch.component.html',
  styleUrls: ['./assign-branch.component.scss']
})
export class AssignBranchComponent implements OnInit {
  typeData;
  data;
  branch;
  processing: boolean;
  constructor(
    private commonService: CommonService,
    private activeModal: NgbActiveModal,
  ) { }

  ngOnInit(): void {
    this.commonService.http.httpGET(EndpointsURL.BRACHES_TO_ASSIGN).subscribe(response => {
      this.typeData = response.data.map(x => {
        return  {id: x.id, text: x.name};
      });
    })
  }

  close() {
    this.activeModal.dismiss();
  }

  save() {
    this.processing = true;
    const payload = {storeId:  this.branch, orderId: this.data.id};
    this.commonService.http.httpPOST(EndpointsURL.ASSING_BRANCH_TO_ORDER, payload).subscribe(response => {
      this.commonService.showToast(response.message, response.successfull ? 'success' : 'danger')
      if (response.successfull) {
        this.activeModal.close(response.data);
      }
      this.processing = false
    }, error => this.processing = false)
  }

}
