import { RouterStateSnapshot, ActivatedRouteSnapshot, Router, CanActivate } from '@angular/router';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { getObject } from '../storage/storage-cap';


@Injectable()
export class AuthorizatedGuard implements CanActivate {

    constructor(
        private router: Router
    ) { }

    async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
        const token = getObject(environment.token)
        if (token) {
            const auth = token;
            if (auth.userInfo) {
                return true;
            } else {
                return false;
            }
        }

        if (!token) {
            this.router.navigate(['login']);
        }
        return !!token;
    }
}
