// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,
  token: 'odey-auth-token',
  // apiURL: 'http://127.0.0.1:8000/api/',
  apiURL: 'https://integracion.appdeone.com/api/',
  urlCliente: 'https://comercio.appdeone.com/',
  OdeyStoreAuth: 'odey-store-permission',
  pushSubscription: 'test_'
};
