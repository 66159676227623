import { ChangeDetectorRef, Injectable } from '@angular/core';
import { Router } from '@angular/router';

import * as moment from 'moment-timezone';
// import { OrderByPipe } from 'ngx-pipes';
import { ToastrService } from 'ngx-toastr';

import { BehaviorSubject, Observable, ReplaySubject } from 'rxjs';
import { getObject, setObject } from '../core/storage/storage-cap';
import { HttpService } from './http.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
moment.locale('es')
@Injectable({
  providedIn: 'root'
})
export class CommonService {
  isLoading = false;
  private subjectCountOrders = new BehaviorSubject<any>({});
  private subjectSignalRConnected = new BehaviorSubject<any>(0);
  private subjectSignalDisconnect = new BehaviorSubject<any>(false);
  private subjectBlockUI = new BehaviorSubject<any>(false);
  constructor(
    private toastCrtl: ToastrService,
    private router: Router,
    public http: HttpService,
    public modalCtrl: NgbModal
  ) {
    moment.locale('es');
   }

  async showToast(message: string, type: 'success' | 'danger' | 'warning'| 'info' | string = 'success', timeOut = 4000, title = '') {
    switch (type) {
      case 'danger':
        this.toastCrtl.error(message, (title ? title : 'Algo ha pasado'), { timeOut });
        break;
      case 'warning':
        this.toastCrtl.warning(message, (title ? title : 'Ciudado'), { timeOut });
        break;
      case 'info':
          this.toastCrtl.info(message, (title ? title : 'Información'), { timeOut });
          break;
      default:
        this.toastCrtl.success(message, (title ? title : 'Completado'), { timeOut });
        break;
    }
  }

  async loadingPresent(loadingMessage: string = '', loadingId: string = '') {
    // this.isLoading = true;

    // const loading = await this.loadingController.create({
    //   id: loadingId,
    //   message: loadingMessage
    // });
    // return await loading.present().then(() => {
    //   if (!this.isLoading) { loading.dismiss(null, null); }
    // });
  }

  async loadingDismiss(loadingId: string = '') {
    // this.isLoading = false;
    // return await this.loadingController.dismiss(null, null, loadingId);
  }

  getBlockIU(): Observable<any> {
    return this.subjectBlockUI.asObservable();
  }

  setBlockIU(data) {
    this.subjectBlockUI.next(data);
  }

  convertFile(file : File) : Observable<string> {
    const result = new ReplaySubject<string>(1);
    const reader = new FileReader();
    reader.readAsBinaryString(file);
    reader.onload = (event) => result.next(btoa(event.target.result.toString()));
    return result;
  }

  /*
    SignalR observables
   */
  getSignalStop(): Observable<any> {
    return this.subjectSignalDisconnect.asObservable();
  }

  sentSignalStop() {
    this.subjectSignalDisconnect.next(true);
  }

  getCountOrders(): Observable<any> {
    return this.subjectCountOrders.asObservable();
  }

  sentCountOrders(data) {
    this.subjectCountOrders.next(data);
  }

  getSignalStatus(): Observable<any> {
    return this.subjectSignalRConnected.asObservable();
  }

  sentSignalStatus(data) {
    this.subjectSignalRConnected.next(data);
  }

  /*
    FIN SignalR observables
   */

  async setStorageObject(name, value) {
    return await setObject(name, value);
  }

  async getStorageObject(name) {
    return await getObject(name).then(value => value);
  }

  goNavToPage(page) {
    this.router.navigateByUrl(page);
  }

  dateFormat(date, format = 'YYYY-MM-DD h:mm a'): string {
    if (!date) {
      return '';
    }
    return moment(date).format(format);
  }

  timeAgoTwoDates(date, dateFrom) {
    let m1 = moment(date, 'DD-MM-YYYY HH:mm');
    let m2 = moment(dateFrom, 'DD-MM-YYYY HH:mm');
    let m3 = m2.diff(m1,'minutes');
    let m4 = m2.diff(m1,'h');

    let numdays = Math.floor(m3 / 1440);
    let numhours = Math.floor((m3 % 1440) / 60);
    let numminutes = Math.floor((m3 % 1440) % 60);

    return ''.concat(numdays > 0 ? numdays.toString().concat(' día(s)') : '')
    .concat(numhours > 0 ? numhours.toString().concat(' h') : '')
    .concat(numminutes > 0 ? numminutes.toString().concat(' m') : '');
  }

  dateAgo(date): string {
    return moment.tz(date, 'America/Costa_Rica').fromNow(true);
  }
}
