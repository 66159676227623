import {ChangeDetectorRef, Component, NgZone, OnInit, ViewChild} from '@angular/core';
import { Event, NavigationEnd, NavigationError, NavigationStart, Router } from '@angular/router';
import { AppSidebarComponent, INavData } from '@coreui/angular';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { FilterPipe } from 'ngx-filter-pipe';
import { environment } from '../../../environments/environment';
import { getObject, setObject } from '../../core/storage/storage-cap';
import { CommonService } from '../../services/common.services';
import { EndpointsURL } from '../../services/endpoints';
import { ICustomNavData, navItems } from '../../_nav';
import { OneSignal } from 'onesignal-ngx';

@Component({
  selector: 'app-dashboard',
  templateUrl: './default-layout.component.html'
})
export class DefaultLayoutComponent implements OnInit {
  public sidebarMinimized = false;
  public navItems;
  userInfo: any;
  loading: boolean;
  @BlockUI('modulo') blockUI: NgBlockUI;
  urlCliente = '';
  @ViewChild('sidebarNav', { static: true}) sidebarNav: AppSidebarComponent;
  constructor(
    private commonService: CommonService,
    private router: Router,
    private filterPipe: FilterPipe,
    private changeDRef: ChangeDetectorRef,
    private oneSignal: OneSignal,
    private ngZone: NgZone
  ) {
    this.commonService.getCountOrders().subscribe(response => {
      if (Object.keys(response).length > 0) {
        this.loadOptions(response);
      }
    });

    this.oneSignal.init({
      appId: "8184dc54-f4e9-47ff-85cf-a7d428bbc17a",
    }).then(response => {});

    this.oneSignal.on('notificationDisplay', response => {
      
      this.ngZone.run(() => {
        this.commonService.showToast(response.content, 'success', 4000, response.heading);
      })
    });

    this.commonService.getBlockIU().subscribe(response => {
      if (response) {
        this.blockUI.start();
      } else {
        this.blockUI.stop();
      }
    });

    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        this.blockUI.stop();
      }

      if (event instanceof NavigationError) {
        this.blockUI.stop();

      }
  });
  }

  toggleMinimize(e) {
    this.sidebarMinimized = e;
  }

  async ngOnInit() {
    this.loading = true;
    this.userInfo = await getObject(environment.token).userInfo;
    this.urlCliente = environment.urlCliente;
    this.oneSignal.sendTag(environment.pushSubscription.concat('user_id'), this.userInfo.id).then(response => {});
    this.commonService.http.httpGET(EndpointsURL.USERINFO.concat('?withRelations=true')).subscribe(async response => {
      this.userInfo = response.data.user;
      const ordersInfo = response.data.orders
      // fix navItems by access
      // Object.keys(this.userInfo.permissions).forEach(permission => {
      const permissions = [];
      for (const [key, value] of Object.entries(this.userInfo.permissions)) {
        if (value !== 'na') permissions.push(value);
      }

      
      setObject(environment.OdeyStoreAuth, permissions);
      // for (const [key, value] of Object.entries(this.userInfo.permissions)) {
        navItems.forEach(itemMenu => {
          if (permissions.includes(itemMenu.url)) {
            itemMenu.show = true;
          }

          // find into childer
          if (itemMenu.children) {
            itemMenu.children.forEach(child => {
              if (permissions.includes(child.url)) {
                child.show = true;
              }
            });
          }
        });
      // }
      await this.loadOptions(ordersInfo);

      this.loading = false;
    }, error => this.loading = false)
  }

  logout() {
    localStorage.clear();
    this.commonService.goNavToPage('/login')
  }

  changeStoreOpen() {
    this.loading = true;
    this.commonService.http.httpPOST(EndpointsURL.CHANGE_STORE_OPEN, {open: !this.userInfo.open}).subscribe(response => {
      this.userInfo = response.data;
      if (response.successfull) {
        this.commonService.showToast('Hemos cambiado el estado del comercio a: ' + (response.data.open ? 'abierto': 'cerrado'), 'success');
      }
      this.loading = false;
    }, error => this.loading = false)
  }

  async loadOptions(ordersInfo) {
    
    const news: ICustomNavData[] = []
    this.navItems = [];
    setTimeout(async () => {
      await navItems.forEach(nav => {
        if (nav.show) {
          // check children
          const children = [];
          if (nav.url === '/manager') {
            if ((ordersInfo.new + ordersInfo.running) > 0 ) {
              nav.badge = {
                variant: 'success',
                text: (ordersInfo.new + ordersInfo.running)
              };
            }
          }

          if (nav.children) {
            nav.children = (nav.children.filter(x => x.show)).length > 0 ? nav.children.filter(x => x.show) : undefined;

            nav.children.forEach(child => {
              if (child.url === '/manager/orders/0') {
                if (ordersInfo.new > 0) {
                  child.badge = {
                    variant: 'success',
                    text: ordersInfo.new
                  };
                }
              }
              if (child.url === '/manager/orders/1-3-4') {
                if (ordersInfo.running > 0) {
                  child.badge = {
                    variant: 'success',
                    text: ordersInfo.running
                  };
                }
              }
            });
          }
          news.push(nav);
        }
      });
      this.navItems = news;
      if (this.sidebarNav) {}
      this.changeDRef.detectChanges();
    }, 10);
  }

  share() {
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = `${this.urlCliente}item/${this.userInfo.id}`;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
  }
}
