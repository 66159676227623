import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { CountryISO, PhoneNumberFormat, SearchCountryField } from 'ngx-intl-tel-input';
import { Subscription } from 'rxjs';
import { CommonService } from '../../../services/common.services';
import { EndpointsURL } from '../../../services/endpoints';
import { HttpService } from '../../../services/http.service';

@Component({
  selector: 'app-create-or-edit-entrust',
  templateUrl: './create-or-edit-entrust.component.html',
  styleUrls: ['./create-or-edit-entrust.component.scss']
})
export class CreateOrEditEntrustComponent implements OnInit {
  @Input() data?: any;
  @Input() title? = 'Crear nueva encomienda';
  showPass = false;
  form: FormGroup;
  color = '#000';
  statesSubscription: Subscription;
  processing: boolean;
  loadingStates = true;
  states: Array<any> = [];
  orderType = 1;
  typeDelivery = 1;
  withCash = false;
  prices;
  checkingAddress = false;
  zoneNoAvailable = false;
  coords: any;
  separateDialCode = false;
	SearchCountryField = SearchCountryField;
	CountryISO = CountryISO;
  PhoneNumberFormat = PhoneNumberFormat;
	preferredCountries: CountryISO[] = [CountryISO.Venezuela];
  constructor(
    private activeModal: NgbActiveModal,
    private formBuilder: FormBuilder,
    private commonService: CommonService,
    private cdRef: ChangeDetectorRef,
    private http: HttpService,
  ) {
    this.setupForm();
  }

  ngOnInit(): void {
    if (this.data) {
      this.title = 'Editar categorīa';
      this.form.patchValue({
        id: this.data.id,
        name: this.data.name,
        status: this.data.status === 0 ? true : false,
        sort_no: this.data.sort_no,
      });
    }
  }

  setupForm() {
    this.form = this.formBuilder.group({
      id: [0, [Validators.required]],
      lat: [null, [Validators.required]],
      lng: [null, [Validators.required]],
      name: [null, [Validators.required]],
      phone: [null, [Validators.required]],
      detail: [null],
      type: [1, [Validators.required]],
      address: [null, [Validators.required]],
      scheduledTime: [null],
      total: [0],
      comission: [0, [Validators.required]],
      notes: [null],
      distance: [0, [Validators.required]],
      entrustOnCar: [false],
      entrustOnMoto: [false],
      own_deliveries: [0],
      amount_payment: [null],
      d_boy_deone: [null],
    });
  }

  close() {
    this.activeModal.close();
  }

  save() {
    this.processing = true;
    const payload: {[k: string]: any} = Object.assign({}, this.form.value);
    payload.lat = this.coords.lat;
    payload.lng = this.coords.lng;
    payload.calculatePrice = true;
    payload.phone = payload.phone.e164Number;
    if (this.typeDelivery === 1) {
      payload.scheduledTime = undefined;
    }
    this.http.httpPOST(EndpointsURL.ENTRUST, payload).subscribe(response => {
      if (response.successfull) {
        this.commonService.showToast(`El registro ${this.form.controls.name.value} ${this.data ? 'actualizado' : 'creado'} correctamente`, 'success');
        this.processing = false;
        this.activeModal.close(response.data);
      }
      this.processing = false;
    }, error => this.processing = false);
  }


  checkTransport(type, event) {
    if (type === 'car' && event.currentTarget.checked) {
      this.form.controls.entrustOnMoto.setValue(false);
    }

    if (type === 'moto' && event.currentTarget.checked) {
      this.form.controls.entrustOnCar.setValue(false);
    }
  }

  validateAddress(calculatePrice = false) {
    this.checkingAddress = true;
    this.zoneNoAvailable = false;
    this.prices = undefined;
    this.coords = undefined;
    const payload = {
      lat: 0,
      lng: 0,
      address: '',
      calculatePrice,
    }

    if (this.orderType === 1) {
      if (this.form.controls.address.invalid) {
        this.commonService.showToast('Debe indicar la dirección de entrega', 'danger', 3000);
        this.checkingAddress = false;
        return;
      }
      payload.address = this.form.controls.address.value;
    } else {
      if (this.form.controls.address.invalid) {
        this.commonService.showToast('Debe indicar la dirección de partida', 'danger', 3000);
        this.checkingAddress = false;
        return;
      }
      payload.address = this.form.controls.address.value;
    }

    this.commonService.http.httpPOST(EndpointsURL.VALIDATE_ADDRESS, payload).subscribe(response => {
      if (response.data.isAvailableDelivery) {
        this.prices = response.data.prices;
        this.coords = response.data.coords;
        this.form.controls.lat.setValue(this.coords.lat);
        this.form.controls.total.setValue(this.prices.total);
        this.form.controls.lng.setValue(this.coords.lng);
        this.updateTreeValidity(this.form);
      } else {
        this.zoneNoAvailable = true;
      }
      this.checkingAddress = false;
    }, error => this.checkingAddress = false);
  }

  updateForm(type) {
    this.orderType = type;
    this.form.controls.type.setValue(type);
  }

  updateTreeValidity(group: FormGroup | FormArray): void {
    Object.keys(group.controls).forEach((key: string) => {
      const abstractControl = group.controls[key];

      if (abstractControl instanceof FormGroup || abstractControl instanceof FormArray) {
        this.updateTreeValidity(abstractControl);
      } else {
        abstractControl.updateValueAndValidity();
      }
    });
  }

  scheduleTimeChange(event) {
  }
}
