import { Component, Input, OnInit } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { CommonService } from '../../../services/common.services';
import { EndpointsURL } from '../../../services/endpoints';
import { AssignBranchComponent } from '../../manager/orders/assign-branch/assign-branch.component';
import { AssignDeliveryComponent } from '../../manager/orders/assign-delivery/assign-delivery.component';
import { ViewVoucherPaymentComponent } from '../../manager/orders/view-voucher-payment/view-voucher-payment.component';

@Component({
  selector: 'app-entrust-table',
  templateUrl: './entrust-table.component.html',
  styleUrls: ['./entrust-table.component.scss']
})
export class EntrustTableComponent implements OnInit {
  @Input() data: Array<any> = []
  @Input() showProducts = false;
  @Input() classed = 0;
  processing: boolean;
  url;
  constructor(
    public commonService: CommonService
  ) { }

  ngOnInit(): void {
    this.url = environment.urlCliente;
  }

  viewVoucherPayment(event, dato, index) {
    event.preventDefault();
    const modal = this.commonService.modalCtrl.open(ViewVoucherPaymentComponent, { size: 'lg', scrollable: true, backdrop: false, centered: true});
    modal.componentInstance.data = dato;
    modal.result.then((result) => {
      if (result) {
        // this.data.splice(index, 1, result);
      }
    });
  }

  async shareLink(event, order) {
    event.preventDefault();
    let json = {
      order_id: order.id,
      store: 'Odey',
      store_address: 'Odey encomiendas',
    };
    let opts = {
      "dynamicLinkInfo": {
        "domainUriPrefix": "https://forwardappdeone.page.link",
        "link": `${this.url}done/${btoa(JSON.stringify(json))}`,
        "androidInfo": {
          "androidFallbackLink": `${this.url}done/${btoa(JSON.stringify(json))}`,
        },
        "iosInfo": {
          "iosFallbackLink": `${this.url}done/${btoa(JSON.stringify(json))}`,
          "iosIpadFallbackLink": `${this.url}done/${btoa(JSON.stringify(json))}`,
        },
        "navigationInfo": {
          "enableForcedRedirect": true,
        },
        "socialMetaTagInfo": {
          "socialTitle": "Odey",
          "socialDescription": '¡Entra acá haz el seguimiento de tu encomienda!',
          "socialImageLink": 'https://appdeone.com/wp-content/uploads/2020/08/Grupo-134.png',
        },
      }
    }

    this.commonService.http.httpPOSTFirebase(opts).subscribe(response => {
      let res: any = response;
      document.addEventListener('copy', (e: ClipboardEvent) => {
        e.clipboardData.setData('text/plain', encodeURI(res.shortLink));
        e.preventDefault();
        document.removeEventListener('copy', null);
      });
      document.execCommand('copy');
      this.commonService.showToast('Copiado al portapapeles.', 'success', 5000, 'Enlace');
    })
  }

  assignBranch(event, dato, index) {
    event.preventDefault();
    const modal = this.commonService.modalCtrl.open(AssignBranchComponent, { size: 'lg', scrollable: true, backdrop: false, centered: true});
    modal.componentInstance.data = dato;
    modal.result.then((result) => {
      if (result) {
        this.data.splice(index, 1);
      }
    });
  }

  assignDelivery(event, dato, index) {
    event.preventDefault();
    const modal = this.commonService.modalCtrl.open(AssignDeliveryComponent, { windowClass: 'ngb-modal-right', scrollable: true, backdrop: false, centered: false });
    modal.componentInstance.data = dato;
    modal.result.then((result) => {
      if (result.d_boy) {
        this.data.splice(index, 1, result);
      }
    });
  }

  confirm(dato, index) {
    const payload = {orderId: dato.id, status: 1};
    this.commonService.http.httpPOST(EndpointsURL.CHANGE_STATUS, payload).subscribe(response => {
      this.commonService.showToast(response.message, response.successfull ? 'success' : 'danger')
      if (response.successfull) {
        this.data.splice(index, 1, response.data);
      }
      this.processing = false
    }, error => this.processing = false)
  }

  cancel(dato, index) {
    const payload = {orderId: dato.id, status: 2};
    this.commonService.http.httpPOST(EndpointsURL.CHANGE_STATUS_ENTRUST, payload).subscribe(response => {
      this.commonService.showToast(response.message, response.successfull ? 'success' : 'danger')
      if (response.successfull) {
        this.data.splice(index, 1);
      }
      this.processing = false
    }, error => this.processing = false)
  }
}
