export async function setObject(name, data) {
  localStorage.setItem(name, JSON.stringify(data))
}

export function getObject(name) {
  let ret = localStorage.getItem(name);
  return JSON.parse(ret);
}

export async function setItem(name, data) {
  localStorage.setItem(name, data);
}

export async function getItem(name) {
  let ret = localStorage.getItem(name);
  return ret;
}

export async function removeItem(name) {
  localStorage.removeItem(name);
}

export function clear() {
  localStorage.clear();
}

