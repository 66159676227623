import { Injectable } from '@angular/core';
import { from, Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { HttpClient} from '@angular/common/http';
import { environment } from '../../environments/environment';

export interface responseAPI {
  successfull?: boolean;
  data?: any;
  message?: string;
  code?: string | Array<any>;
}
// 
@Injectable({
  providedIn: 'root'
})
export class HttpService {

  constructor(
    private http: HttpClient
  ) { }

  httpGET(url): Observable<responseAPI> {
    return this.http.get<any>(environment.apiURL.concat(url))
    .pipe(
      map(response => this.decryptData(response))
    );
  }

  httpPOST(url, data = {}): Observable<responseAPI> {
    return this.http.post<any>(environment.apiURL.concat(url), data)
    .pipe(
      map(response => this.decryptData(response))
    );
  }

  httpPOSTFirebase(data = {}): Observable<responseAPI> {
    return this.http.post<any>('https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=AIzaSyDtOjInz5SDJXRz1IEKn0InrNYzvVwkHT0', data)
    .pipe(
      map(response => response)
    );
  }

  httpDELETE(url, id): Observable<responseAPI> {
    return this.http.delete<any>(environment.apiURL.concat(url).concat(id))
    .pipe(
      map(response => this.decryptData(response))
    );
  }

  decryptData(data) {
    return data;
    if (typeof data === 'string') {
      return JSON.parse(decodeURIComponent(escape(atob(data))));
    }
    return data;
  }
}
