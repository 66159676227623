import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OrdersTableComponent } from '../views/manager/orders/orders-table/orders-table.component';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { AssignDeliveryComponent } from '../views/manager/orders/assign-delivery/assign-delivery.component';
import { AssignBranchComponent } from '../views/manager/orders/assign-branch/assign-branch.component';
import { NgSelect2Module } from 'ng-select2';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ViewVoucherPaymentComponent } from '../views/manager/orders/view-voucher-payment/view-voucher-payment.component';
import { OrdersComponent } from '../views/manager/orders/orders.component';
import { BlockUIModule } from 'ng-block-ui';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
import { EntrustComponent } from '../views/entrust/entrust.component';
import { EntrustTableComponent } from '../views/entrust/entrust-table/entrust-table.component';
import { CreateOrEditEntrustComponent } from '../views/entrust/create-or-edit-entrust/create-or-edit-entrust.component';
import { AddPaymentComponent } from '../views/manager/orders/add-payment/add-payment.component';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { SpinnerComponent } from './spinner/spinner.component';
import { PrintOrderComponent } from '../views/manager/orders/print-order/print-order.component';
import { NgxPrintModule } from 'ngx-print';
@NgModule({
  declarations: [
    OrdersTableComponent,
    EntrustTableComponent,
    AssignDeliveryComponent,
    ViewVoucherPaymentComponent,
    PrintOrderComponent,
    AddPaymentComponent,
    AssignBranchComponent,
    OrdersComponent,
    EntrustComponent,
    CreateOrEditEntrustComponent,
    SpinnerComponent
  ],
  imports: [
    CommonModule,
    SweetAlert2Module.forRoot(),
    BsDropdownModule.forRoot(),
    NgSelect2Module,
    SweetAlert2Module.forRoot(),
    FormsModule,
    ReactiveFormsModule,
    NgxIntlTelInputModule,
    TabsModule,
    BlockUIModule.forRoot({
      message: 'Por favor espere mientras cargamos la información'
    }),
    NgxPrintModule
  ],
  exports: [
    CommonModule,
    OrdersTableComponent,
    AssignDeliveryComponent,
    ViewVoucherPaymentComponent,
    PrintOrderComponent,
    AddPaymentComponent,
    TabsModule,
    AssignBranchComponent,
    OrdersComponent,
    EntrustComponent,
    NgxIntlTelInputModule,
    EntrustTableComponent,
    CreateOrEditEntrustComponent,
    SpinnerComponent,
    NgxPrintModule
  ],
  providers: [

  ]
})
export class SharedModule { }
