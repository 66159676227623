import { Component } from '@angular/core';
import { environment } from '../../../environments/environment';
import { setObject } from '../../core/storage/storage-cap';
import { CommonService } from '../../services/common.services';
import { EndpointsURL } from '../../services/endpoints';

@Component({
  selector: 'app-dashboard',
  templateUrl: 'login.component.html'
})
export class LoginComponent {
  showMessage = false;
  errorMessage: string;
  processing = false;
  form = {
    email: '',
    password: ''
  }
  constructor(private commonService: CommonService) {
    localStorage.clear();
  }

  login() {
    this.processing = true;
    this.commonService.http.httpPOST(EndpointsURL.LOGIN, this.form).subscribe(response => {
      if (response.successfull)  {
        setObject(environment.token, response.data);
        this.processing = false;
        this.commonService.goNavToPage('/')
      }
      this.processing = false;
    }, error => {
      this.commonService.showToast('Ocurrio un error al procesar su solicitud', 'danger');
      this.processing = false;
    });
  }
}
