import { INavData } from '@coreui/angular';
export interface ICustomNavData extends INavData {
  show?: boolean
}

export const navItems: ICustomNavData[] = [
  {
    name: 'Panel',
    url: '/dashboard',
    icon: 'icon-speedometer',
    // badge: {
    //   variant: 'info',
    //   text: 'NEW'
    // },
    show: true
  },
  {
    name: 'Ajustes',
    url: '/base/basic-info',
    icon: 'cil-cog',
    show: true
  },
  {
    name: 'Menú de productos',
    url: '/product',
    icon: 'icon-puzzle',
    show: true,
    children: [
      {
        name: 'Categorías',
        url: '/product/categories',
        icon: 'cil-briefcase',
        show: true
      },
      {
        name: 'Productos',
        url: '/product/products',
        icon: 'cil-braille',
        show: true
      },
      {
        name: 'Complementos',
        url: '/product/addons',
        icon: 'cil-library-add',
        show: true
      },
    ]
  },
  {
    name: 'Inventario',
    url: '/inventory',
    icon: 'cil-storage',
    show: false,
    children: [
      {
        name: 'Productos',
        url: '/inventory/products',
        icon: 'cil-braille',
        show: true
      },
      {
        name: 'Movimientos',
        url: '/inventory/movements',
        icon: 'cil-list',
        show: true
      },
    ]
  },
  {
    name: 'Pedidos',
    url: '/manager',
    icon: 'cil-notes',
    show: true,
    children: [
      {
        name: 'Nuevos',
        url: '/manager/orders/0',
        icon: 'cil-bell',
        show: true
      },
      {
        name: 'En curso',
        url: '/manager/orders/1-3-4',
        icon: 'cil-gauge',
        show: true
      },
      {
        name: 'Cancelados',
        url: '/manager/orders/2',
        icon: 'cil-x-circle',
        show: true
      },
      {
        name: 'Completados',
        url: '/manager/orders/5',
        icon: 'cil-check-circle',
        show: true
      }
    ]
  },
  {
    name: 'Encomiendas',
    url: '/entrust',
    icon: 'cil-flight-takeoff',
    show: true,
    children: [
      {
        name: 'En curso',
        url: '/entrust/list/1-3-4',
        icon: 'cil-gauge',
        show: true
      },
      {
        name: 'Canceladas',
        url: '/entrust/list/2',
        icon: 'cil-x-circle',
        show: true
      },
      {
        name: 'Completadas',
        url: '/entrust/list/5',
        icon: 'cil-check-circle',
        show: true
      }
    ]
  },
  {
    name: 'Ofertas & cupones',
    url: '/discount',
    icon: 'cil-happy',
    show: true,
    children: [
      {
        name: 'Ofertas',
        url: '/discount/discount',
        icon: 'cil-tags',
        show: true
      },
      {
        name: 'Cupones',
        url: '/discount/coupon',
        icon: 'cil-tags',
        show: true
      },
    ]
  },
  {
    name: 'Zonas de entrega',
    url: '/base/zone-delivery',
    icon: 'cil-location-pin',
    show: false
  },
  {
    name: 'Mis mensajeros',
    url: '/base/deliveries',
    icon: 'cil-bike',
    show: false
  },
  {
    name: 'Usuarios Cocina APP',
    url: '/kitchen',
    icon: 'cil-fastfood',
    show: false,
  },
  {
    name: 'Colores de tienda',
    url: '/theme',
    icon: 'cil-color-palette',
    show: false
  },
  {
    name: 'Reportes',
    url: '/report',
    icon: 'cil-bar-chart',
    show: false,
    children: [
      {
        name: 'Ventas totales',
        url: '/report/total-sales',
        icon: 'cil-bar-chart',
        // badge: {
        //   variant: 'success',
        //   text: 'NEW'
        // },
        show: false
      },
    ]
  },
  {
    name: 'Salir',
    url: '/login',
    icon: 'cil-account-logout',
    class: 'mt-auto',
    show: true
  },
];
